import React from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Pagination from '@/components/pagination';
import Post, { Node } from '@/components/post';
import { graphql } from 'gatsby';

interface QueryProps {
  allFile: {
    nodes: {
      childMarkdownRemark: Node;
    }[];
  };
}

export default function posts({ data, pageContext }: { data: QueryProps, pageContext: any }) {
  const posts = data.allFile.nodes.map(({ childMarkdownRemark: node }) => <Post node={node} />);

  return (
    <Layout>
      <SEO title="Farm Journal" />

      <div className="mx-auto" style={{ maxWidth: '900px' }}>
        <h1
          className="pt-8 text-4xl font-bold md:text-5xl text-center"
          title="Farm Journal">
          Farm Journal
        </h1>

        <section className="w-full px-4 py-12 mx-auto max-w-7xl md:w-3/4">
          {pageContext.pageNumber === 0 && (
            <p className="pb-8">
              This is more or less a log of what goes on day by day on the farmstead. I'm hoping that we'll be able to better remember decisions we made, what we were doing at any point in time, and see how things progress. It's more for me than anyone else, but you might find it interesting too!
            </p>
          )}

          {posts}
        </section>

        <Pagination {...pageContext} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allFile(
      filter: {sourceInstanceName: {eq: "posts"}}
      sort: {fields: childrenMarkdownRemark___frontmatter___publish_date, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            publish_date
            tags
            title
          }
          fields {
            slug
          }
          excerpt(pruneLength: 200, truncate: true)
        }
      }
    }
  }
`;
